
:root {
  --standard-link-color: black;
  --hover-link-color: #37a;
  --active-link-color: red;
  --global-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.App {
  font-family: var(--global-font);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.no-wrap {
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}

.full {
  width: 100%;
}

.flexed {
  display: flex; 
  align-items: center;
  flex-wrap: nowrap;
}

.flexed.top {
  align-items: baseline;
}

.flexed.center {
  justify-content: center;
}

.flexed.right {
  justify-content: left;
}

.flexed.wrap {
  flex-wrap: wrap;
}


.app-label {
  background: #e0e0e0;
  border-radius: 3px;
  color: #444;
  padding: 2px 6px;
  margin-right: 2px;
  font-size: .7rem;
}

.app-link {
  cursor: pointer;
  color: var(--standard-link-color); 
}
.app-link:hover {
  color: var(--hover-link-color);
  text-decoration: underline;
}

.app-link:active {
  color: var(--active-link-color)
}


.icon-button-no-padding.sm {
  padding: 0 6px !important;
}

.red {
  color: red
}

.bold {
  font-weight: 600;
} 


.pull-right {
  margin-left: auto;
}

ul.parser-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  max-width: 500px;
  flex-wrap: wrap;
}

ul.parser-test-response {
  list-style: none;
  padding: 0;
  margin: 0; 
  max-width: 500px; 
}

ul.parser-test-response li {
  margin: 4px;
}

ul.parser-test-response li label {
  width: 80px;
  min-width: 80px;
  text-align: right;
  text-transform: uppercase;
  color: gray;
  font-size: 0.7rem;
}


ul.parser-list li {
  margin: 4px;
  width: 150px;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vertical-button-column {
  vertical-align: top;
  height: 400px;
  width: 56px;
}

.parser-edit-content {
  vertical-align: text-top;
  min-height: 400px;
}



.app-region-menu {
  position: absolute;
  top: -180px;
  transition: top 0.2s linear;
  z-index: 2;
}
.app-region-menu.open {
  top: 0;
}
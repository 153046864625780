.PlaylistModal {
  display: flex;
} 

#alert-dialog-title {
  font-size: 0.9rem !important;
  padding: 16px 0 0 24px;
  display: flex;
}

.modal-paper {
  max-width: 742px;
}
.modal-paper.small {
  max-width: 662px;
}

.modal-title {

  display: flex;
}

.modal-title .MuiTypography-h6 {
  font-size: 0.9rem !important;
  font-weight: normal;
  padding: 0;
  display: flex;
}

.loader-avatar {
  width: 56px;
  height: 56px;
}